import axios from "axios";
import generarFacturaJson from "../helpers/generarFacturaJson";

const verificar = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
  const totalProductos = JSON.parse(localStorage.getItem("totalProductos"));
  const prefijo = localStorage.getItem("prefijoFront") || null;
  const clienteString = localStorage.getItem("cliente");
  const cliente = clienteString ? JSON.parse(clienteString) : null;
  const CuponClover = JSON.parse(localStorage.getItem("CuponClover"));
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  let facturaJsonPuntos = generarFacturaJson(CuponClover, puesto, cliente, facturaInfo, totalProductos, prefijo, null);

  const userData = {
    nic: formattedNic,
    token: token,
    datos: facturaJsonPuntos,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Facturacion/VerificarRemitoAnticipado`, userData);

    return response.data;
  } catch (error) {
    throw error;
  }
};

const RemitoAnticipado = {
  verificar,
};

export default RemitoAnticipado;
