import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Modal, CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom"; // Importar useLocation para obtener la URL
import { useTheme } from "@mui/material/styles";
import { handlePagoEvent, showLoadingSpinner } from "../helpers/pagosHelper";
import DebitoIcon from "../images/debito.png";
import EfectivoPagoIcon from "../images/efectivo-icon.png";
import CreditoIcon from "../images/credito.png";
import qr from "../images/qr.png";
import MenuSection from "../components/MenuSection";
import estadoDespacho from "../api/estadoDespacho";
import ConsultarSaldoShellBox from "../api/consultarSaldoShellBox";
import { useCanjeStore } from "../hooks";

const FormasDePagoPage = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Hook para obtener los parámetros de la URL
  const [isLoading, setIsLoading] = useState(false);
  const [showPuntosModal, setShowPuntosModal] = useState(false);
  const [isCheckingPoints, setIsCheckingPoints] = useState(false);
  const theme = useTheme();
  const bandera = localStorage.getItem("bandera");
  const listadoQR = localStorage.getItem("conListadoQR");
  const { premio, dniCanje } = useCanjeStore();

  const handleEfectivoEvent = async event => {
    const responsePagoEfectivoJava = event.detail.success;
    localStorage.setItem("responsePagoEfectivoJava", responsePagoEfectivoJava);

    if (responsePagoEfectivoJava) {
      setIsLoading(true);

      const despachosSeleccionados = localStorage.getItem("despachosSeleccionados");

      if (despachosSeleccionados) {
        const despachosArray = JSON.parse(despachosSeleccionados); // Asegúrate de que sea un array
        try {
          // Usamos un bucle for...of para esperar cada despacho antes de navegar
          for (const despachoId of despachosArray) {
            await estadoDespacho.actualizarDespacho(despachoId, 1, 1);
          }
          navigate("/dialogoimprimirfacturapage");
        } catch (error) {
          console.error("Error al actualizar despacho:", error);
          // Manejar error si es necesario
        }
      } else {
        navigate("/dialogoimprimirfacturapage");
      }
    }
  };
  const handlePuntosAccept = () => {
    localStorage.setItem("cargaPuntosServiClub", true);
    setShowPuntosModal(false);
    handleQR();
  };

  const handlePuntosReject = () => {
    localStorage.setItem("cargaPuntosServiClub", false);
    setShowPuntosModal(false);
  };

  useEffect(() => {
    const eventListener = handlePagoEvent(setIsLoading, navigate);

    window.addEventListener("responsePagoConTarjeta", eventListener);
    window.addEventListener("responsePagoEfectivo", handleEfectivoEvent);

    return () => {
      window.removeEventListener("responsePagoConTarjeta", eventListener);
      window.removeEventListener("responsePagoEfectivo", handleEfectivoEvent);
    };
  }, []);

  // Ejecutar la función de pago automáticamente si se ha seleccionado una forma de pago en la URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search); // Obtener parámetros de la URL
    const medioDePago = searchParams.get("medioDePago"); // Extraer "medioDePago" de la URL

    if (medioDePago) {
      switch (medioDePago) {
        case "debito":
          handleDebito();
          break;
        case "credito":
          handleCredito();
          break;
        case "efectivo":
          handleCloverEfectivo();
          break;
        case "qr":
          handleDebito();
          break;
        default:
          break;
      }
    }
  }, [location]); // Ejecutar el efecto cuando cambie la ubicación (URL)

  const handleDebito = () => {
    let valueToSend = parseFloat(localStorage.getItem("totalProductos"));

    if (dniCanje && premio?.descuento) {
      valueToSend -= premio.descuento;
    }

    valueToSend = valueToSend.toString();
    const useTips = JSON.parse(localStorage.getItem("useTips")); // Obtener el valor de propinas

    try {
      window.MyInterface.startPayment(valueToSend, null, useTips);
    } catch (error) {
      console.error("Falló la llamada con tres parámetros, intentando con dos.");
      window.MyInterface.startPayment(valueToSend, null);
    }
  };

  const handleCloverEfectivo = () => {
    let valueToSend = parseFloat(localStorage.getItem("totalProductos"));

    if (dniCanje && premio?.descuento) {
      valueToSend -= premio.descuento;
    }

    valueToSend = valueToSend.toString();
    localStorage.removeItem("CuponClover");

    window.MyInterface.startPaymentEfectivo(valueToSend);
  };

  const handleCredito = () => {
    navigate("/cuotaspage");

  };

  const handleQR = () => {
    if (listadoQR == "true") {
      navigate("/listadoqrpage");
    } else if (listadoQR == "false") {
      navigate("/pagoqrpage");
    } else {
      navigate("/listadoqrpage");
    }
  };

  // Función para manejar la elección de pago y verificar si se deben acumular puntos
  const handleEleccionPago = async (medioDePago, metodoPagoCallback) => {
    const bandera = localStorage.getItem("bandera");

    if (bandera === "shell") {
      let redimirSuccess = false;
      let acumulaSuccess = false;

      // Llamada a sugerirRedimirLatamPass
      try {
        const response = await ConsultarSaldoShellBox.sugerirRedimirLatamPass();
        console.log("sugerirRedimirLatamPass status:", response.status);
        redimirSuccess = response.status === 200;
      } catch (error) {
        // Si ocurre error (por ejemplo, status 500) lo marcamos como fallo
        console.error("Error en sugerirRedimirLatamPass:", error);
        redimirSuccess = false;
      }

      // Llamada a acumulaPuntos
      try {
        const responseAcumula = await ConsultarSaldoShellBox.acumulaPuntos();
        console.log("acumulaPuntos status:", responseAcumula.status);
        acumulaSuccess = responseAcumula.status === 200;
      } catch (error) {
        console.error("Error en acumulaPuntos:", error);
        acumulaSuccess = false;
      }

      // Si ambos endpoints fallan, se ejecuta el callback de método de pago
      if (!redimirSuccess && !acumulaSuccess) {
        return metodoPagoCallback();
      }

      // Se arma la URL con los parámetros según el resultado de cada llamada
      const queryParams = new URLSearchParams({ medioDePago });
      queryParams.append("sugerirRedimirLatamPass", redimirSuccess ? "true" : "false");
      queryParams.append("acumulaPuntos", acumulaSuccess ? "true" : "false");

      navigate(`/obtenerdnipuntos?${queryParams.toString()}`);
    } else {
      metodoPagoCallback();
    }
  };

  return (
    <>
      {/* SPINNER DE CARGA */}
      {isCheckingPoints && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100vw",
            height: "65vh",
            bgcolor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CircularProgress size={60} />
        </Box>
      )}
      <MenuSection />
      {isLoading && showLoadingSpinner()}
      <Box
        sx={{
          position: "absolute",
          top: window.innerWidth < 700 ? "8%" : "12%",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h3
          style={{
            backgroundColor: theme.bandera.colorSecundario,
            color: "white",
            fontWeight: "bold",
            padding: "20px 22px",
            width: "100%",
            textAlign: "center",
            whiteSpace: "nowrap",
            margin: 0,
          }}
        >
          SELECCIONE UNA FORMA DE PAGO:
        </h3>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: window.innerWidth < 700 ? "repeat(2, 1fr)" : "repeat(3, 1fr)",
            gap: "80px",
            alignItems: "center",
            mt: 6,
          }}
        >
          <Button
            sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }}
            onClick={() => handleEleccionPago("debito", handleDebito)} // Primero verificamos si se acumulan puntos
          >
            <img src={DebitoIcon} alt="Debito" style={{ width: "70px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              DÉBITO
            </Typography>
          </Button>

          <Button
            sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }}
            onClick={() => handleEleccionPago("credito", handleCredito)} // Primero verificamos si se acumulan puntos
          >
            <img src={CreditoIcon} alt="Credito" style={{ width: "70px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              CRÉDITO
            </Typography>
          </Button>

          <Button
            sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }}
            onClick={() => handleEleccionPago("qr", handleDebito)} // Primero verificamos si se acumulan puntos
          >
            <img src={qr} alt="QR FISERV" style={{ width: "60px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              QR FISERV
            </Typography>
          </Button>

          <Button
            sx={{ width: "70px", height: "70px", display: "flex", flexDirection: "column", alignItems: "center" }}
            onClick={() => handleEleccionPago("efectivo", handleCloverEfectivo)} // Primero verificamos si se acumulan puntos
          >
            <img src={EfectivoPagoIcon} alt="Efectivo" style={{ width: "60px" }} />
            <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
              EFECTIVO
            </Typography>
          </Button>

          {(bandera === "ypf" || bandera === "shell") && (
            <Button
              sx={{
                width: "70px",
                height: "70px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={handleQR}
            >
              <img src={theme.bandera.logoPago} alt="Clover" style={{ width: "60px" }} />
              <Typography width={"140px"} color="black" variant="subtitle2" fontWeight="bold" marginTop={0.5}>
                {theme.bandera.textoBotonPago}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      {/* MODAL PARA ACUMULAR PUNTOS SERVICLUB */}
      <Modal open={showPuntosModal} onClose={() => {}}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 311, // Más ancho
            height: 250, // Más alto
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
            ¿Desea sumar los puntos a su tarjeta Serviclub?
          </Typography>
          <Box display="flex" justifyContent="space-evenly">
            <Button
              variant="contained"
              onClick={handlePuntosReject}
              sx={{
                width: "90px",
                height: "90px",
                backgroundColor: theme.palette.grey[400],
                color: "white",
                fontWeight: "bold",
                borderRadius: "8px",
                fontSize: 18,
                ":hover": { backgroundColor: theme.palette.grey[500] },
              }}
            >
              NO
            </Button>
            <Button
              variant="contained"
              onClick={handlePuntosAccept}
              sx={{
                width: "90px",
                height: "90px",
                backgroundColor: theme.bandera.colorSecundario,
                color: "white",
                fontWeight: "bold",
                borderRadius: "8px",
                fontSize: 18,
                ":hover": { backgroundColor: theme.bandera.colorSecundario },
              }}
            >
              SI
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FormasDePagoPage;
