import React, { useState, useEffect } from "react";
import { Modal, Box, Button, Typography, IconButton, useMediaQuery, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuSection from "../components/MenuSection";
import { useCanjeStore } from "../hooks";

const DialogoImprimirFacturaPage = () => {
  const navigate = useNavigate();
  const isScreenWidthLessThan600 = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Estado para manejar el spinner
  const [timeLeft, setTimeLeft] = useState(15); // Estado para manejar la cuenta regresiva
  const { clearCanje } = useCanjeStore();

  // Función para navegar a la página con o sin impresión
  const sinImpresion = () => {
    const facturaInfoString = localStorage.getItem("facturaInfo");
    const facturaInfo = JSON.parse(facturaInfoString);
    const imprimir = false;
    localStorage.setItem("limpiarArticulos", false);
    clearCanje();
    navigate("/ticket/", { state: { facturaInfo, imprimir } });
  };

  const mandarAImprimir = () => {
    const facturaInfoString = localStorage.getItem("facturaInfo");
    const facturaInfo = JSON.parse(facturaInfoString);
    const imprimir = true;
    localStorage.setItem("limpiarArticulos", false);
    clearCanje();
    navigate("/ticket/", { state: { facturaInfo, imprimir } });
  };

  // UseEffect para contar los 30 segundos y simular el clic en "No"
  useEffect(() => {
    setLoading(true); // Activamos el spinner al iniciar la cuenta regresiva

    const countdownInterval = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      setLoading(false); // Desactivamos el spinner después de 30 segundos
      sinImpresion(); // Ejecuta la función de "No" automáticamente
    }, 15000); // 30 segundos de espera

    // Cleanup de los intervalos
    return () => {
      clearInterval(countdownInterval);
      clearTimeout(timeout);
    };
  }, []);



  useEffect(() => {
    // Reemplaza la entrada actual en el historial
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const handleBackButton = () => {
    // Función para manejar el evento cuando se presiona el botón de retroceso
    // Aquí puedes redirigir a otra página o simplemente hacer nada
    navigate("/dialogoimprimirfacturapage"); // Redirecciona a otra página según sea necesario
  };
  
  const theme = useTheme();

  return (
    <>
      <MenuSection />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          width: isScreenWidthLessThan600 ? "95%" : "100%",
          height: isScreenWidthLessThan600 ? "70%" : "100%",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
          onClick={sinImpresion}
        >
          <CloseIcon />
        </IconButton>
        <h3
          style={{
            marginTop: isScreenWidthLessThan600 ? "60px" : "160px",
            backgroundColor: theme.bandera.colorSecundario,
            color: "white",
            fontWeight: "bold",
            padding: "20px 70px",
            borderRadius: "4px",
            width: isScreenWidthLessThan600 ? "90%" : "100%",
            textAlign: "center",
            whiteSpace: isScreenWidthLessThan600 ? "normal" : "nowrap",
          }}
        >
          ¿Quiere imprimir el comprobante?
        </h3>

        {/* Botones de SI y NO siempre visibles */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "40px",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Box>
            <Button
              variant="contained"
              onClick={mandarAImprimir}
              sx={{
                borderRadius: 2,
                fontWeight: "bold",
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
                fontSize: 20,
                width: 130,
                height: 110,
                flexDirection: "column",
              }}
            >
              SI
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={sinImpresion}
              sx={{
                borderRadius: 2,
                fontWeight: "bold",
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
                fontSize: 20,
                width: 130,
                height: 110,
                flexDirection: "column",
              }}
            >
              NO
            </Button>
          </Box>
        </Box>
        {/* Mostramos el spinner y el tiempo restante mientras cargamos */}
        {loading && (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 4 }}>
            <CircularProgress />
            <Typography sx={{ mt: 2, fontWeight: "bold" }}>{timeLeft} segundos restantes</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DialogoImprimirFacturaPage;
