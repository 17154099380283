import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Grid, Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuSection from "../components/MenuSection";
import { useTheme } from "@mui/material/styles";
import { vehiculosByCliente } from "../api/getVehiculosByCliente";

const DatosChofer = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const chofer = JSON.parse(localStorage.getItem("chofer")) || {};
  const cliente = JSON.parse(localStorage.getItem("cliente")) || {};

  const [numeroVehiculo, setNumeroVehiculo] = useState(chofer.numeroVehiculo || "");
  const [patente, setPatente] = useState(chofer.patente || "");
  const [kilometraje, setKilometraje] = useState(chofer.kilometraje || "");
  const [vehiculos, setVehiculos] = useState([]);
  const [modalWidth, setModalWidth] = useState(window.innerWidth < 700 ? "360px" : "900px");

  useEffect(() => {
    const fetchVehiculos = async () => {
      try {
        const idCliente = cliente.idCliente;
        const response = await vehiculosByCliente(idCliente);
        setVehiculos(response.data || []); // Guardar los vehículos devueltos por el endpoint
      } catch (error) {
        console.error("Error al obtener los vehículos del cliente:", error);
      }
    };

    fetchVehiculos();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setModalWidth(window.innerWidth < 700 ? "360px" : "900px");
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCancel = () => {
    navigate("/choferespage");
  };

  const handleAgregar = () => {
    const updatedChoferData = {
      ...chofer,
      numeroVehiculo: numeroVehiculo.toUpperCase(),
      patente: patente.toUpperCase(),
      kilometraje,
    };

    localStorage.setItem("chofer", JSON.stringify(updatedChoferData));
    localStorage.setItem("completoDatosChofer", true);
    navigate("/dashboard");
  };

  const botonAceptarDeshabilitado = () => {
    const requiereVehiculo = cliente.exigeVehiculo ? numeroVehiculo === "" : false;
    const requierePatente = cliente.exigePatente ? patente === "" : false;
    const requiereKilometraje = cliente.exigeKm ? kilometraje === "" : false;

    return requiereVehiculo || requierePatente || requiereKilometraje;
  };

  const handleVehiculoSelect = (event, value) => {
    if (value) {
      setNumeroVehiculo(value.numero);
      setPatente(value.patente);
    } else {
      setNumeroVehiculo("");
      setPatente("");
    }
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: modalWidth,
          top: window.innerWidth < 700 ? "49%" : "54%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          height: window.innerWidth < 700 ? "75vh" : "90%",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ mb: 4, color: theme.bandera.colorSecundario, fontWeight: 500, fontSize: "1.5rem" }}>{chofer.nombreChofer || ""}</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {vehiculos.length > 0 ? (
              <Autocomplete
                options={vehiculos}
                getOptionLabel={option => `${option.numero}`}
                onChange={handleVehiculoSelect}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Seleccionar Vehículo"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      style: { fontSize: "0.85rem", color: "gray" },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    {...props}
                    key={option.idVehiculo}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      fontSize: "0.9rem",
                      p: 1,
                      gap: "5px",
                    }}
                  >
                    <span style={{ flex: 1 }}>{option.numero}</span>
                    <span style={{ flex: 1, textAlign: "right" }}>{option.patente}</span>
                  </Box>
                )}
                value={vehiculos.find(v => v.numero === numeroVehiculo) || null}
              />
            ) : (
              <TextField
                label="N° vehículo"
                value={numeroVehiculo}
                onChange={e => setNumeroVehiculo(e.target.value)}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  style: { fontSize: "0.85rem", color: "gray" },
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Patente"
              value={patente}
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: vehiculos.length > 0, // La patente es de solo lectura si hay vehículos disponibles
              }}
              InputLabelProps={{
                style: { fontSize: "0.85rem", color: "gray" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Kilometraje"
              value={kilometraje}
              onChange={e => setKilometraje(e.target.value)}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                style: { fontSize: "0.85rem", color: "gray" },
              }}
              type="number"
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: window.innerWidth < 700 ? "center" : "flex-end",
            width: "100%",
            maxWidth: "300px",
            marginTop: "120px",
            boxSizing: "border-box",
          }}
        >
          <Button
            variant="contained"
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              fontSize: 17,
              marginTop: "19px",
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleAgregar}
            disabled={botonAceptarDeshabilitado()}
          >
            ACEPTAR
          </Button>
          <Button
            variant="contained"
            sx={{
              boxShadow: 0,
              fontWeight: "bold",
              fontSize: 17,
              marginLeft: "20px",
              marginTop: "19px",
              backgroundColor: theme.bandera.colorSecundario,
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
            onClick={handleCancel}
          >
            CANCELAR
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DatosChofer;
