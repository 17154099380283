import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { handlePagoEvent, showLoadingSpinner } from "../helpers/pagosHelper";
import MenuSection from "../components/MenuSection";
import { useProductStore, useCanjeStore } from "../hooks";

const CuotasPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCuota, setSelectedCuota] = useState("1");
  const nic = localStorage.getItem("formattedNic");
  const { productos, updateProducto } = useProductStore();
  const theme = useTheme();
  const totalProductos = +localStorage.getItem("totalProductos");
  const { premio, dniCanje } = useCanjeStore();

  useEffect(() => {
    const productosFromLocalStorage = localStorage.getItem("productos");
    if (productos.length === 0 && productosFromLocalStorage) {
      updateProducto(JSON.parse(productosFromLocalStorage));
    }
  }, [productos, updateProducto]);

  useEffect(() => {
    const eventListener = handlePagoEvent(setIsLoading, navigate);
    window.addEventListener("responsePagoConTarjeta", eventListener);

    return () => {
      window.removeEventListener("responsePagoConTarjeta", eventListener);
    };
  }, [navigate]);

  if (!productos || productos.length === 0) {
    return <Typography variant="h6">Cargando productos...</Typography>;
  }

  const calcularTotalCuotas = (totalProductos, productos, cuotaSeleccionada, descuento = 0) => {
    const total = productos.reduce((total, producto) => {
      if (cuotaSeleccionada === "1") {
        return totalProductos;
      } else if (cuotaSeleccionada === "3") {
        return total + (producto.precioLista3 || 0);
      } else if (cuotaSeleccionada === "6") {
        return total + (producto.precioLista6 || 0);
      }
      return total;
    }, 0);
    return (total - descuento).toFixed(2); // Aplica descuento y asegura dos decimales
  };

  const descuento = premio?.descuento || 0;

  let cuotas = [];
  if (nic === "https://laposta.caldenoil.com:5000/") {
    cuotas = [
      { cuota: "1", total: calcularTotalCuotas(totalProductos, productos, "1", descuento), numCuotas: 1 },
      { cuota: "3", total: calcularTotalCuotas(totalProductos, productos, "3", descuento), numCuotas: 3 },
      { cuota: "6", total: calcularTotalCuotas(totalProductos, productos, "6", descuento), numCuotas: 6 },
    ].filter(option => option.cuota === "1" || option.total > 0);
  } else {
    cuotas = [{ cuota: "1", total: calcularTotalCuotas(totalProductos, productos, "1", descuento), numCuotas: 1 }].filter(
      option => option.cuota === "1" || option.total > 0,
    );
  }

  const handleSubmit = () => {
    const selectedOption = cuotas.find(option => option.cuota === selectedCuota);
    const totalConCuotas = selectedOption.total;
    const numeroCuotas = selectedOption.numCuotas;

    const useTips = JSON.parse(localStorage.getItem("useTips")); // Obtener el valor de propinas

    try {
      window.MyInterface.startPayment(totalConCuotas, numeroCuotas, useTips);
    } catch (error) {
      console.error("Falló la llamada con tres parámetros, intentando con dos.");
      window.MyInterface.startPayment(totalConCuotas, numeroCuotas);
    }
  };

  return (
    <>
      <MenuSection />
      {isLoading && showLoadingSpinner()}
      <Box mx={2} mt={window.innerWidth < 700 ? 3 : -8}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Elige la cantidad de cuotas
        </Typography>
        <Box mt={4} display="flex" flexDirection="column" sx={{ accentColor: theme.bandera.colorSecundario }} alignItems="flex-start">
          {cuotas.map(option => (
            <label key={option.cuota} style={{ marginBottom: "20px" }}>
              <input type="radio" value={option.cuota} checked={selectedCuota === option.cuota} onChange={() => setSelectedCuota(option.cuota)} />
              {option.cuota} cuota(s) de ${(option.total / option.numCuotas).toFixed(2)} (Total: ${option.total})
            </label>
          ))}
        </Box>
        <Box
          sx={{
            marginTop: window.innerWidth > 700 ? 2 : 32,
            display: "flex",
            justifyContent: window.innerWidth > 700 ? "center" : "flex-end",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.bandera.colorSecundario, ":hover": { backgroundColor: theme.bandera.colorSecundario } }}
            onClick={handleSubmit}
          >
            <Typography variant="button" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              CONTINUAR
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CuotasPage;
